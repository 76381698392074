import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.updatePlaceholder();
    this.element.addEventListener('input', this.updatePlaceholder.bind(this));
  }

  updatePlaceholder() {
    this.element.dataset.isEmpty = this.element.textContent.trim() === '' ? 'true' : 'false';
  }
}
